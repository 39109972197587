import usePrefix from '@/hooks/usePrefix';
import { DownOutlined, MobileOutlined } from '@/components2/Icons';
import MenuMobile from '@/components2/MenuMobile';
import { useMobxStores } from '@stores/index';
import { Divider, Dropdown, Menu, Space, Image } from 'antd';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { ReactElement, useEffect, useState } from 'react';
import Give5Modal from './Give5Modal';
import { modalType } from './Modal';
import { CDN2 } from '@/helpers/api';
import { DrawerRebook } from './DrawerRebook';
import AirportDropdown from './AirportsDropdown';
import AirportDrawer from './AirportsDrawer';

const menu = (store, prefix) => {
  function handleOnClick() {
    modalType({
      type: 'info',
      title: 'Give $5 and Get $5!',
      content: Give5Modal(store),
    });
  }
  return (
    <Menu
      key="1"
      id="dropdown-user-menu"
      className="flex flex-col gap-y-2 w-48 h-auto"
    >
      <Menu.Item className="!mt-2" key="1.1">
        <Link href={prefix + '/myreservation'}>My Reservations</Link>
      </Menu.Item>
      <Menu.Item key="1.2">
        <Link href={prefix + '/myreservation/account'}>My Account</Link>
      </Menu.Item>
      {/* <Menu.Item
        key="1.3"
        className="font-normal text-md"
        onClick={handleOnClick}
      >
        Give 5 Get 5
      </Menu.Item> */}
      <Divider type="horizontal" className="!-mt-1 !mb-1" />
      <Menu.Item key="1.4">
        <Link href="/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  );
};

interface INavebar {
  airports: any;
  isHomepage?: boolean;
}

function NavBar({ airports, isHomepage }: INavebar): ReactElement {
  const { authStore, uiStore } = useMobxStores();
  const [loggedIn, setLoggedIn] = useState(false);
  const prefix = usePrefix();

  useEffect(() => {
    setLoggedIn(authStore.signedIn);
  }, [authStore, authStore.signedIn]);
  return (
    <>
      <nav className="bg-white z-11 border-b md:border-none">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center w-full">
              <div className="mr-2 flex-shrink-0">
                <a href="/">
                  <Image
                    src={CDN2() + '/staticmyapp/logo-registred.svg'}
                    width={120}
                    height={60}
                    alt="On Air Parking"
                    preview={false}
                  />
                </a>
              </div>
              <ul
                className={`flex md:hidden items-baseline justify-center`}
                id="menu-link-2"
              >
                <AirportDrawer
                  airports={airports?.result}
                  isLogged={loggedIn}
                />
                {loggedIn ? (
                  <DrawerRebook />
                ) : (
                  <>
                    <li>
                      <Link
                        href={prefix + '/city'}
                        className="font-bold !text-base mx-2 "
                      >
                        City
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={prefix + '/cruise'}
                        className="font-bold !text-base  mx-2 "
                      >
                        Cruise
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="hidden md:flex justify-center " id="menu-link-2">
                <li className="ml-2">
                  <AirportDropdown airports={airports?.result} />
                </li>
                <li className="ml-2">
                  <Link
                    href={prefix + '/city'}
                    className="font-bold lg:!text-sm !text-xs mx-2"
                  >
                    City
                  </Link>
                </li>
                <li className="ml-2">
                  <Link
                    href={prefix + '/cruise'}
                    className="font-bold lg:!text-sm !text-xs mx-2"
                  >
                    Cruise
                  </Link>
                </li>
                <li className="ml-2">
                  <Link
                    href="/blog"
                    className="font-bold lg:!text-sm !text-xs mx-2 "
                  >
                    Blog
                  </Link>
                </li>
                <li className="ml-2">
                  <Link
                    href={prefix + '/contact'}
                    className="font-bold lg:!text-sm !text-xs mx-2"
                  >
                    Contact
                  </Link>
                </li>
                {isHomepage && (
                  <li className="ml-2">
                    <Link
                      href={prefix + '/partner'}
                      className="font-bold lg:!text-sm !text-xs mx-2"
                    >
                      Partner with us
                    </Link>
                  </li>
                )}
              </ul>
              <ul
                className={`hidden md:flex ml-auto items-baseline justify-center `}
                id="menu-link-2"
              >
                <li>
                  <Link
                    href={prefix + '/myreservation/getapp'}
                    className="font-bold lg:!text-sm !text-xs mr-3"
                  >
                    <MobileOutlined className="mr-1" />
                    Get the App
                  </Link>
                </li>
                <li>{loggedIn && <DrawerRebook />}</li>

                {!loggedIn && (
                  <li className="!mr-2">
                    <Link
                      href={prefix + '/login'}
                      passHref
                      className="flex bg-white border hover:border-2 border-primary hover:no-underline rounded-full mx-auto  w-[4.2rem] !text-[0.7rem] justify-center items-center sign-in"
                    >
                      Sign in
                    </Link>
                  </li>
                )}
                {!loggedIn && (
                  <li>
                    <Link
                      href={prefix + '/signup'}
                      passHref
                      className="flex bg-primary !text-white !hover:text-white hover:hover:opacity-75 hover:no-underline rounded-full   w-[4.2rem]  !text-[0.7rem] justify-center items-center sign-up"
                    >
                      Sign up
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {loggedIn && (
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="ml-3 relative">
                    <div>
                      <button
                        className="max-w-xs rounded-full flex items-center text-sm focus:outline-none "
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <Space direction="vertical">
                          <Space wrap>
                            <Dropdown
                              overlay={menu(authStore, prefix)}
                              placement="bottomRight"
                            >
                              <div className="flex flex-row w-max justify-center items-center">
                                <p className=" lg:!text-sm !text-xs">
                                  Hi, {authStore.user.member_first_name}
                                </p>
                                {/* <Gravatar
                                email={authStore.user.member_login}
                                size={32}
                                className="rounded-full border"
                                default="mp"
                              /> */}
                                <DownOutlined className="ml-1" />
                              </div>
                            </Dropdown>
                          </Space>
                        </Space>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex md:hidden absolute rounded-bl-full w-12 h-12 top-0 right-0 mr-2 ">
              <button
                className="inline-flex items-center justify-center absolute top-4 right-1.5 rounded-full text-primary focus:outline-none "
                onClick={() => uiStore.toggleMenu()}
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`flex md:hidden relative transition-all ease-out delay-150 ${
              uiStore.showMenu ? 'h-auto overflow-y-hidden' : 'h-0'
            } `}
          >
            <MenuMobile />
          </div>
        </div>
        <div className={`flex flex-col md:hidden mb-2`}>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />

          <div className="flex flex-row justify-center mt-1">
            <p className="text-xs font-bold italic p-1">
              Enjoy time and cost savings with our app!
            </p>
            <Link
              href={prefix + '/myreservation/getapp'}
              className="bg-primary text-white text-xs font-bold ml-2 rounded-md p-1"
            >
              Get the App
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default observer(NavBar);
